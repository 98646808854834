import { createRouter, createWebHashHistory } from 'vue-router'
import versionTood from '@/common/versionUpdate'
import Frame from '@/views/frame'
import Home from '@/views/home/index.vue'
import ParentCourse from '@/views/course-manage/parent-course.vue'
import TeacherCourse from '@/views/course-manage/teacher-course.vue'
import CourseDetail from '@/views/course-manage/detail.vue'
import ParentList from '@/views/parent-manage/list.vue'
import ParentDetail from '@/views/parent-manage/detail.vue'
import ChildList from '@/views/parent-manage/child-list.vue'
import ChildDetail from '@/views/parent-manage/child-detail.vue'
import ChildWorkDetail from '@/views/parent-manage/child-work-detail.vue'
import MatchList from '@/views/parent-manage/match-list.vue'
import TeacherList from '@/views/teacher-manage/list.vue'
import TeacherDetail from '@/views/teacher-manage/detail.vue'
import TeacherComment from '@/views/teacher-manage/comment.vue'
import NotificationList from '@/views/notification-manage/list.vue'
import NotificationDetail from '@/views/notification-manage/detail.vue'
import QuestionList from '@/views/question-manage/list.vue'
import QuestionDetail from '@/views/question-manage/detail.vue'
import QAList from '@/views/qa-manage/list.vue'
import QADetail from '@/views/qa-manage/detail.vue'
import WorkList from '@/views/work-manage/list.vue'
import WorkDetail from '@/views/work-manage/detail.vue'
import ProfessorList from '@/views/professor-manage/list.vue'
import ProfessorEdit from '@/views/professor-manage/edit.vue'
import Login from '@/views/login/login.vue'
import NotFound from '@/views/error/not-found.vue'


const routes = [
    {
        path: '/',
        name: 'frame',
        component: Frame,
        redirect: {
            name: 'parentList'
        },
        children: [
            {
                path: 'home',
                name: 'home',
                component: Home,
                meta: {
                    hideNav: false,
                    hideLeftNav: true,
                    activeOn: 'home'
                }
            },
            {
                path: 'course-manage',
                auth: 'course',
                redirect: {
                    name: 'parentCourse'
                },
                children: [
                    {
                        path: 'parent-course',
                        name: 'parentCourse',
                        component: ParentCourse,
                        meta: {
                            hideNav: false,
                            hideLeftNav: false,
                            activeOn: 'course'
                        }
                    },
                    {
                        path: 'teacher-course',
                        name: 'teacherCourse',
                        component: TeacherCourse,
                        meta: {
                            hideNav: false,
                            hideLeftNav: false,
                            activeOn: 'course'
                        }
                    },
                    {
                        path: 'course-detail/:id/:courseType',
                        name: 'courseDetail',
                        component: CourseDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'course'
                        }
                    }
                ]
            },
            {
                path: 'qa-management',
                redirect: {
                    name: 'qaList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'qaList',
                        component: QAList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'qa'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'qaDetail',
                        component: QADetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'qa'
                        }
                    },
                ]
            },
            {
                path: 'notification-management',
                redirect: {
                    name: 'notificationList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'notificationList',
                        component: NotificationList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'notification'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'notificationDetail',
                        component: NotificationDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'notification'
                        }
                    },
                ]
            },
            {
                path: 'question-management',
                redirect: {
                    name: 'questionList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'questionList',
                        component: QuestionList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'question'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'questionDetail',
                        component: QuestionDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'question'
                        }
                    },
                ]
            },
            {
                path: 'parent-management',
                redirect: {
                    name: 'parentList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'parentList',
                        component: ParentList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: false,
                            activeOn: 'parents'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'parentDetail',
                        component: ParentDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'parents'
                        }
                    },
                    {
                        path: 'child-list',
                        name: 'childList',
                        component: ChildList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: false,
                            activeOn: 'parents'
                        }
                    },
                    {
                        path: 'child-detail/:id',
                        name: 'childDetail',
                        component: ChildDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'parents'
                        }
                    },
                    {
                        path: 'child-work-detail/:id',
                        name: 'childWorkDetail',
                        component: ChildWorkDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'parents'
                        }
                    },
                    {
                        path: 'match-list',
                        name: 'matchList',
                        component: MatchList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: false,
                            activeOn: 'parents'
                        }
                    }
                ]
            },
            {
                path: 'teacher-management',
                redirect: {
                    name: 'teacherList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'teacherList',
                        component: TeacherList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'teachers'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'teacherDetail',
                        component: TeacherDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'teachers'
                        }
                    },
                    {
                        path: 'comment/:id',
                        name: 'teacherComment',
                        component: TeacherComment,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'teachers'
                        }
                    }
                ]
            },
            {
                path: 'work-management',
                redirect: {
                    name: 'workList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'workList',
                        component: WorkList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'works'
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'workDetail',
                        component: WorkDetail,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'works'
                        }
                    },
                ]
            },
            {
                path: 'professor-management',
                redirect: {
                    name: 'professorList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'professorList',
                        component: ProfessorList,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'professor'
                        }
                    },
                    {
                        path: 'edit/:editType/:uid?',
                        name: 'professorEdit',
                        component: ProfessorEdit,
                        meta: {
                            hideNav: false,
                            hideLeftNav: true,
                            activeOn: 'professor'
                        }
                    }
                ]
            },
            {
                path: 'login',
                name: 'login',
                component: Login,
                meta: {
                    hideNav: true,
                    hideLeftNav: true,
                }
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'notFound',
                component: NotFound,
                meta: {
                    hideNav: false,
                    hideLeftNav: true,
                }
            }
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    next()
})
export default router
