import request from "@/common/axios";

/**
 * 获取通知列表数据
 * @param {Object} data
 * @param {string} data.target_type - 通知对象类型 relation/question
 * @param {string} data.target_ulid - 通知对象ulid
 * @param {string} data.status - -1删除0未读1已读
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @returns {*}
 */
export function getNotificationList(data) {
    return request({
        url: '/v1/notification',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取通知数据
 * @param {string} ulid - 通知ulid
 * @returns {*}
 */
export function getNotification(ulid) {
    return request({
        url: '/v1/notification/' + ulid,
        method: 'GET',
    })
}

/**
 * 修改数据
 * @param {string} ulid - 通知ulid
 * @param {object} data
 * @param {number} data.is_recommended
 * @param {number} data.status
 * @returns {*}
 */
export function updateNotification(ulid, data) {
    return request({
        url: '/v1/notification/' + ulid,
        method: 'PATCH',
        data
    })
}
