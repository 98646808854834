<template>
    <span>hello world</span>
</template>

<script>
export default {
    name: "detail"
}
</script>

<style scoped lang="scss">

</style>