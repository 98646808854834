<template>
    <div class="list">
        <div class="header-title">
            <span style="text-align: left">{{name}}</span>
        </div>
        <div v-if="relations.length === 0" class="no-data">
            <img src="@/assets/images/no-log.png" alt="暂无推荐信息">
            <div class="desc">暂无推荐信息</div>
        </div>
        <div v-else>
            <el-table :data="relations" style="width: 100%;">
                <el-table-column type="index" label="序号" width="60"></el-table-column>
                <el-table-column
                    v-for="column in computedColumns"
                    :key="column.id"
                    :label="column.display"
                    :width="get(column, 'width')"
                    :show-overflow-tooltip="true"
                    :fixed="get(column, 'fixed', false)"
                >
                    <template #default="scope">
                        <span v-if="column.id === 'state'" :class="[formattedRelationClassName(scope.row['state'])]">{{ formattedRelationState(scope.row['state']) }}</span>
                        <span v-else-if="column.id === 'created_at'">{{ formatDate(scope.row['created_at']) }}</span>
                        <span v-else-if="column.id === 'updated_at'">{{ formatDate(scope.row['updated_at']) }}</span>
                        <span v-else-if="column.id === 'child.display_name'">{{ get(scope.row, 'child.display_name', '-') }}</span>
                        <span v-else-if="column.id === 'parent.display_name'">{{ get(scope.row, 'parent.display_name', '-') }}</span>
                        <span v-else-if="column.id === 'parent.phone'">{{ get(scope.row, 'parent.phone', '-') }}</span>
                        <span v-else-if="column.id === 'teacher.display_name'">{{ get(scope.row, 'teacher.display_name', '-') }}</span>
                        <span v-else-if="column.id === 'teacher.phone'">{{ get(scope.row, 'teacher.phone', '-') }}</span>
                        <span v-else-if="column.id === 'teacher.age'">{{ calculateAge(get(scope.row, 'teacher.birthday', '-')) }}</span>
                        <span v-else-if="column.id === 'teacher.gender'">{{ genderTypeMap[get(scope.row, 'teacher.gender', 'unknown')]?.display }}</span>
                        <span v-else-if="column.id === 'teacher.level'">{{ levelTypeMap[get(scope.row, 'teacher.level', 'unknown')]?.display }}</span>
                        <!--                        <span v-else-if="column.id === 'content'" class="overflow-span" :show-overflow-tooltip="true">{{ scope.row[column.id] }}</span>-->
                        <span v-else>{{ scope.row[column.id] ?? "" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="200">
                    <template v-if="showOperate"  #default="scope">
                        <span v-if="canOperate(scope.row, relations)" class="primary-btn" @click="handleState(scope.row)">更新状态</span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 弹出的对话框 -->
            <el-dialog v-model="dialogVisible" :visible.sync="dialogVisible" :append-to-body="true" title="修改状态和内容">
                <el-form :model="editForm">
                    <el-form-item label="状态">
                        <el-select v-model="editForm.state" placeholder="请选择状态">
                            <el-option
                                v-for="item in nextStateList"
                                :key="item"
                                :label="item?.display"
                                :value="item?.key"
                                :disabled="item?.disabled ?? false"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="内容">
                        <el-input v-model="editForm.content" type="textarea" max=500 rows="6" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveChanges">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

import {
    formattedRelationClassName,
    formattedRelationState,
    genderTypeMap,
    relationStateList,
    relationStateMap,
    relationStates,
    showStatusMap
} from "@/config/data-config";
import {levelTypeMap} from "@/config/teacher-config";
import _ from "lodash";
import {getRelationList, updateRelation} from "@/services/relation";
import {calculateAge} from "@/common/util";
import {formatDate} from "@/utils/datetime";

export default {
    components: {},
    props: {
        relations: {
            type: Array,
            default: () => {
                return [];
            }
        },
        name: {
            type: String,
            default: '推荐列表'
        },
        showParent: {
            type: Boolean,
            default: true
        },
        showTeacher: {
            type: Boolean,
            default: true
        },
        showChild: {
            type: Boolean,
            default: false
        },
        showOperate: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            columns: [
                {
                    id: 'child.display_name',
                    display: '孩子姓名',
                    width: 150,
                    sortable: false
                },
                {
                    id: 'parent.display_name',
                    display: '家长姓名',
                    width: 150,
                    sortable: false
                },
                {
                    id: 'parent.phone',
                    display: '联系方式',
                    width: 150,
                    sortable: false,
                },
                {
                    id: 'teacher.display_name',
                    display: '教师姓名',
                    width: 150,
                    sortable: false
                },
                {
                    id: 'teacher.phone',
                    display: '联系方式',
                    width: 150,
                    sortable: false,
                },
                {
                    id: 'teacher.gender',
                    display: '教师性别',
                    width: 100,
                    sortable: false
                },
                {
                    id: 'teacher.age',
                    display: '教师年龄',
                    width: 100,
                    sortable: false
                },
                {
                    id: 'teacher.level',
                    display: '教师级别',
                    width: 100,
                    sortable: false
                },
                {
                    id: 'content',
                    display: '备注',
                    width: '',
                    fixed: "right",
                    sortable: false
                },
                {
                    id: 'state',
                    display: '状态',
                    width: 80,
                    fixed: "right",
                    sortable: false
                },
                {
                    id: 'created_at',
                    display: '创建时间',
                    width: 160,
                    fixed: "right",
                    sortable: false
                },
                {
                    id: 'updated_at',
                    display: '更新时间',
                    width: 160,
                    fixed: "right",
                    sortable: false
                },
            ],
            dialogVisible: false, // 控制对话框显示与隐藏
            editForm: {
                ulid: "",
                state: "",
                content: "",
            }, // 编辑表单的数据
            nextStateList: []
        };
    },
    watch: {
        // items: {
        //     handler(newValue, oldValue) {
        //         // 在这里处理 items 变化后的逻辑，例如重新渲染表格等
        //         console.log('Items changed', newValue, oldValue);
        //         this.$forceUpdate();
        //     },
        //     deep: true,// 如果 items 是一个嵌套的对象或数组，设置 deep 为 true 以深度监听其内部属性的变化
        // }
    },
    computed: {
        computedColumns() {
            let newColumns = [...this.columns];

            if (!this.showChild) {
                newColumns = newColumns.filter(
                    (column) =>!column.id.startsWith("child.")
                );
            }
            if (!this.showParent) {
                newColumns = newColumns.filter(
                    (column) =>!column.id.startsWith("parent.")
                );
            }
            if (!this.showTeacher) {
                newColumns = newColumns.filter(
                    (column) =>!column.id.startsWith("teacher.")
                );
            }
            return newColumns;
        },
        relationStateMap() {
            return relationStateMap
        },
        showStatusMap() {
            return showStatusMap
        },
        levelTypeMap() {
            return levelTypeMap
        },
        genderTypeMap() {
            return genderTypeMap
        }

    },
    created() {
        // console.log('Received data:', this.relations);
        // this.items = this.data;
    },
    methods: {
        formatDate,
        formattedRelationClassName,
        formattedRelationState,
        calculateAge,
        relationStateList() {
            return relationStateList
        },
        get: _.get,
        handleState(row) {
            console.log("handleState", row)
            this.showDialog(row)
        },
        showDialog(row) {
            let state = row?.state
            // let item = relationStateMap[state]
            let nextState = this.getNextState(state)

            this.editForm.ulid = row?.ulid;
            // this.editForm.state = state;
            this.editForm.state = nextState[0];
            this.editForm.content = row?.content;
            this.dialogVisible = true;
            this.nextStateList = nextState.map(item => {
                let itemNew = relationStateMap[item]

                console.log('showDialog item', itemNew)
                itemNew['disabled'] = false;
                return itemNew
            })
            // item['disabled'] = true;
            // this.nextStateList.push(item)
            console.log('showDialog nextState', this.nextStateList)
            console.log('showDialog editForm', this.editForm, this.dialogVisible)
        },
        async saveChanges() {
            // 这里模拟保存修改后的数据
            console.log("修改后的状态：", this.editForm.state);
            console.log("修改后的内容：", this.editForm.content);

            try {
                const data = {
                    state: this.editForm.state,
                    content: this.editForm.content,
                }

                await updateRelation(this.editForm.ulid, data)
                this.$message.success("更新成功")
                this.$emit('relationUpdated');
            } catch (e) {
                this.$message.error(e.message)
            }

            this.dialogVisible = false;
        },
        formatState(row, column) {

        },
        canOperate(item, list) {
            const hasProbationary = list.some((entry) => entry.state === relationStates.probationary);

            if (hasProbationary && item.state !== relationStates.probationary) {
                return false;
            }
            const hasMatched = list.some((entry) => entry.state === relationStates.employed);

            if (hasMatched && item.state !== relationStates.employed) {
                return false;
            }
            // 根据当前状态判断是否可以操作
            if (item.state === relationStates.probationary) {
                return true;
            } else if (item.state === relationStates.employed) {
                return this.getNextState(item.state).includes(relationStates.terminated);
            } else {
                return !this.getNextState(item.state).includes(relationStates.unknown);
            }
        },
        getNextState(state) {
            switch (state) {
            case relationStates.recommended:
                return [relationStates.interviewed, relationStates.unmatched];
            case relationStates.interviewed:
                return [relationStates.passed, relationStates.eliminated];
            case relationStates.passed:
                return [relationStates.probationary, relationStates.rejected_probationary]
            case relationStates.probationary:
                return [relationStates.rejected_extended_probationary,relationStates.extended_probationary, relationStates.unemployed, relationStates.employed, relationStates.terminated];
            case relationStates.extended_probationary:
                return [relationStates.unemployed, relationStates.employed, relationStates.terminated]
            case relationStates.employed:
                return [relationStates.terminated];
            case relationStates.terminated:
                return [relationStates.recommended];
            default:
                return [relationStates.unknown];
            }
        },
        updateState(item, list) {
            const nextState = this.getNextState(item.state);

            if (nextState) {
                item.state = nextState;
            }
            // 更新其他条目的按钮状态
            list.forEach((entry) => {
                entry.canOperate = this.canOperate(entry, this.list);
            });
        },
    }
};
</script>

<style lang='scss' scoped>
//.overflow-span {
//    white-space: nowrap;
//    overflow: hidden;
//    text-overflow: ellipsis;
//}
@import '@/assets/styles/list.scss';
</style>
