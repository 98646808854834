import _ from 'lodash'
import { roleAuth } from './data-config'

export const mainMenuList = [
    // {
    //     id: 'home',
    //     display: '首页',
    //     icon: 'sd-icon-home',
    //     route: {
    //         name: 'home'
    //     }
    // },
    // {
    //     id: 'course',
    //     display: '课堂管理',
    //     icon: 'sd-icon-lm-knowledge',
    //     route: {
    //         name: 'parentCourse'
    //     }
    // },
    {
        id: 'qa',
        display: '答疑管理（旧）',
        icon: 'sd-icon-lm-knowledge',
        route: {
            name: 'qaList'
        }
    },
    {
        id: 'notification',
        display: '通知管理',
        icon: 'sd-icon-im-notify',
        route: {
            name: 'notificationList'
        }
    },
    {
        id: 'question',
        display: '答疑管理',
        icon: 'sd-icon-lm-knowledge',
        route: {
            name: 'questionList'
        }
    },
    {
        id: 'parents',
        display: '家长管理',
        icon: 'sd-icon-users',
        route: {
            name: 'parentList'
        }
    },
    {
        id: 'teachers',
        display: '教师管理',
        icon: 'sd-icon-lm-patient',
        route: {
            name: 'teacherList'
        }
    },
    {
        id: 'works',
        display: '督导报告管理',
        icon: 'sd-icon-docs',
        route: {
            name: 'workList'
        }
    },
    {
        id: 'professor',
        display: '专家管理',
        icon: 'sd-icon-account',
        route: {
            name: 'professorList'
        }
    },
]

export const leftMenuList = [
    {
        id: 'parentCourse',
        name: '家长课堂管理',
        parentId: 'course',
        icon: 'sd-icon-data',
        route: {
            name: 'parentCourse'
        },
    },
    {
        id: 'teacherCourse',
        name: '教师课堂管理',
        parentId: 'course',
        icon: 'sd-icon-microscope',
        route: {
            name: 'teacherCourse'
        },
    },
    {
        id: 'parentList',
        name: '家长管理',
        parentId: 'parents',
        icon: 'sd-icon-add-patients',
        route: {
            name: 'parentList'
        },
    },
    {
        id: 'childList',
        name: '孩子管理',
        parentId: 'parents',
        icon: 'sd-icon-single-col',
        route: {
            name: 'childList'
        },
    },
    // {
    //     id: 'matchList',
    //     name: '待匹配列表',
    //     parentId: 'parents',
    //     icon: 'sd-icon-table',
    //     route: {
    //         name: 'matchList'
    //     },
    // }
]

export function getLeftMenu(parentId, role) {
    const lmList = []
    const leftList = _.filter(leftMenuList, item => item.parentId === parentId)

    _.map(leftList, menu => {
        const key = menu.id
        const authList = roleAuth[key].menuShow

        if (authList.includes(role)) {
            lmList.push(menu)
        }
    })
    return lmList
}
